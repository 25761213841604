import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KeyStatsNotFound.css';
import { Button } from 'primereact/button';

const KeyStatsNotFound = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    roomCount: '',
    occupancy: '',
    adr: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.occupancy > 100) {
      alert('Occupancy cannot be more than 100');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/key_stats_notFound', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        navigate('/TTMtable', { state: { data: responseData } });
      } else {
        console.error('Failed to submit key stats');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="key-stats-container">
      {isLoading && <div className="loading-message m-auto">Submitting... please wait.</div>}
      {!isLoading && (
        <form onSubmit={handleSubmit} className="key-stats-form">
          <h1 className='h4 text-600 py-2'>Enter Key Stats</h1>
          <div className="form-group">
            <label htmlFor="roomCount">Room count</label>
            <input
              type="number"
              id="roomCount"
              name="roomCount"
              value={formData.roomCount}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="occupancy">Occupancy (Max 100)</label>
            <input
              type="number"
              id="occupancy"
              name="occupancy"
              value={formData.occupancy}
              onChange={handleInputChange}
              max="100"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="adr">ADR</label>
            <input
              type="number"
              id="adr"
              name="adr"
              value={formData.adr}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button label='Submit' type="submit" className='button' />
        </form>
      )}
    </div>
  );
};

export default KeyStatsNotFound;
