import { Button } from 'primereact/button';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./OperationalHistory.module.css"
const OperationalHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let data = location.state?.data || [];

  const goToTTMtablePage = async () => {
    try {
      const response = await fetch('/api/back', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'identifier' : 'TTM'}),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        const responseData = await response.json();
        navigate('/TTMtable', { state: { data: responseData } });
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProceed = async () => {
    try {
      const response = await fetch('/api/save_ops_history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        navigate('/CMPreport');
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getDisplayValue = (value) => {
    return value === null || value === undefined || value === '' ? '-' : value;
  };

  return (
    <div>
      <div className='flex justify-content-between align-items-center'>
        <div>
          <h1 className='h4 text-600 py-2'>Operational History</h1>
          <p className='h5 text-500'>Click on proceed to generate Competition & Market Penetration report.</p>
        </div>
        <div className="button-container">
          <Button label='Back' className="button outlineButton" outlined onClick={goToTTMtablePage} />
          <Button label='Proceed' className='button' onClick={handleProceed} />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead className={styles.thead}>
          <tr>
            <th>Index</th>
            <th>Value</th>
            <th>% REV</th>
            <th>POR</th>
            <th>PAR</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{getDisplayValue(item.index)}</td>
              <td>{getDisplayValue(item.Total)}</td>
              <td>{getDisplayValue(item['% REV'])}</td>
              <td>{getDisplayValue(item.POR)}</td>
              <td>{getDisplayValue(item.PAR)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OperationalHistory;
