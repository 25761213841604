import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IEreports.css';
import { Button } from 'primereact/button';

const IEreports = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetch('/api/ie-reports');
        if (!response.ok) {
          throw new Error('Failed to fetch reports');
        }
        const data = await response.json();
        setReportData(data);
      } catch (error) {
        setError('Error fetching reports');
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  const goToCMPreport = async () => {
    try {
      const response = await fetch('/api/back', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'identifier' : 'cmp'}),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        const responseData = await response.json();
        navigate('/CMPreport', { state: { data: responseData } });
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProceed = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/save_ie_report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
      });

      if (!response.ok) {
        throw new Error('Failed to save report');
      }

      navigate('/ValuationReports');
    } catch (error) {
      console.error('Error saving report:', error);
      setError('Error saving report');
    } finally {
      setIsLoading(false);
    }
  };

  const renderTableHeader = () => {
    if (reportData.length === 0) return null;
    const headers = reportData.columns;
    return (
      <tr>
        <th>Index</th>
        {headers.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    );
  };

  const renderTableRows = () => {
    if (reportData.length === 0) return null;
    const rows = reportData.data;
    const indexes = reportData.index;
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        <td>{indexes[rowIndex]}</td>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex}>{cell}</td>
        ))}
      </tr>
    ));
  };

  return (
    <div>
      <div className='flex justify-content-between align-items-center mb-3'>
        <h1 className='h4 text-600 py-2'>Income & Expense Report</h1>
        <div className="button-container">
          <Button label='Back' className="button outlineButton" outlined onClick={goToCMPreport} />
          <Button label={isLoading ? 'Saving...' : 'Proceed'} className='button' onClick={handleProceed} />
        </div>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!isLoading && !error && (
        <div className='overflowTable pl-2'>
          <table className="table table-striped table-hover">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableRows()}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default IEreports;
