import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ValuationReports.css';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';

const ValuationReports = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [capitalisationRate, setCapitalisationRate] = useState('');
  const [roomRevenueRate, setRoomRevenueRate] = useState('');
  const [grossRevenueRate, setGrossRevenueRate] = useState('');
  const [valueEstimateIncrement, setValueEstimateIncrement] = useState(0.25);
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let data = location.state?.data || [];

  useEffect(() => {
    if (data.length > 0) {
      const deserializedData = data.map((item) => JSON.parse(item));
      setReportData(deserializedData);
      console.log('Initial data set:', data);
    }
  }, [data]);


  const goToIEreportsPage = () => {
    navigate('/IEreports');
  };

  const handleProceed = async () => {
    navigate('/FRFreport');
  };

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (!isNaN(value) || value === '') {
      setter(value);
    }
  };

  const handleGenerateReport = async () => {
    if (
      capitalisationRate === '' ||
      roomRevenueRate === '' ||
      grossRevenueRate === '' ||
      isNaN(capitalisationRate) ||
      isNaN(roomRevenueRate) ||
      isNaN(grossRevenueRate)
    ) {
      setError('All fields must be numbers');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = {
      capitalisationRate: Number(capitalisationRate),
      roomRevenueRate: Number(roomRevenueRate),
      grossRevenueRate: Number(grossRevenueRate),
      valueEstimateIncrement,
    };

    try {
      const response = await fetch('/api/get_val_app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to generate report');
      }

      const responseData = await response.json();
      const deserializedData = responseData.map((item) => JSON.parse(item));
      setReportData(deserializedData);
    } catch (error) {
      console.error('Error generating report:', error);
      setError('Error generating report');
    } finally {
      setIsLoading(false);
    }
  };

  const tableTitles = { 0: 'Discounted Cash Flow Analysis', 1: 'Valuation Analysis Inputs', 2: 'Valuation Matrix - Room Revenue Multiplier', 3: 'Valuation Matrix - Direct Capitalization Rate Analysis', 4: 'Valuation Matrix - Gross Revenue Multiplier', 5: 'Valuation Methodology Summary', 6: 'Valuation Conclusion' }
  const renderTables = () => {
    return reportData.map((table, index) => (
      <div className='m-0 p-0 mt-2'>
        <h3>{tableTitles[index]}</h3>
        <table key={index} className='valuationReportTable table table-striped table-hover ml-0 pl-0'>
          <thead>
            <tr>
              <th>Index</th>
              {table.columns.map((header, indexT) => (
                <th key={indexT}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td key={index + '_' + rowIndex}>{table.index[rowIndex]}</td>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ));
  };

  return (
    <div>
      <div className='flex justify-content-between align-items-center'>
        <h1 className='h4 text-600 py-2'>Valuation Reports</h1>
        <div className="button-container">
          <Button label='Back' className="button outlineButton" onClick={goToIEreportsPage} />
          <Button label='Proceed' className='button' disabled={!reportData} onClick={handleProceed} />
        </div>
      </div>

      <div className='row  m-0 p-0 border rounded mt-2 ml-1'>
        <div className='col mr-1'>
          <label className="form-label">Direct Capitalisation Rate:</label>
          <input
            className='form-control'
            type="text"
            value={capitalisationRate}
            onChange={handleInputChange(setCapitalisationRate)}
            placeholder="Enter a number"
          />
        </div>
        <div className='col mr-1'>
          <label className="form-label">Room Revenue Multiplier:</label>
          <input
            className='form-control'
            type="text"
            value={roomRevenueRate}
            onChange={handleInputChange(setRoomRevenueRate)}
            placeholder="Enter a number"
          />
        </div>
        <div className='col mr-1'>
          <label className="form-label">Gross Revenue Rate:</label>
          <input
            className='form-control'
            type="text"
            value={grossRevenueRate}
            onChange={handleInputChange(setGrossRevenueRate)}
            placeholder="Enter a number"
          />
        </div>
        <div className='col mr-1'>
          <label className="form-label">Value Estimate Increment:</label>
          <div className="flex justify-content-start gap-3 ">
            <Slider
              id="growthFactor"
              value={valueEstimateIncrement}
              onChange={(e) => setValueEstimateIncrement(parseFloat((e.value)))}
              className="w-8rem form-control mt-2"
              min={0}
              max={1}
              step={0.01}
              minFractionDigits={2}
            />
            <span className='p-1 border rounded'>{valueEstimateIncrement}</span>
          </div>
        </div>
        <div className='col-12 col-lg-3'>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button className='button w-5 ml-3 mt-0' label={isLoading ? 'Generating Valuation Report...' : 'Generate Valuation Report'} disabled={isLoading} onClick={handleGenerateReport} />
        </div>
      </div>
      {reportData && renderTables()}

    </div>
  );
};

export default ValuationReports;
