import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import Modal from 'react-modal';
import styles from './ShowListOfTemplates.module.css'; // Create this file for Templates styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import { Button } from 'primereact/button';

const Templates = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('/api/fetch_templates');
        setTemplates(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const openModal = (template, image) => {
    setSelectedTemplate(template);
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedTemplate(null);
    setSelectedImage('');
  };

  const navigateToUploads = () => {
    navigate('/uploadFiles');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading templates: {error.message}</div>;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className='px-2'>
      <h1 className='h4 text-600'>Choose Templates</h1>
      <div className="row">
        {templates.map(template => (
          <React.Fragment className="px-1">
            <div key={template.template_id} className={`${styles.template} col-md-4 gap-2`}>
              {template.image_path.length > 1 ? (
                <Slider {...settings} >
                  {template.image_path.map((path, index) => (
                    <div key={index} onClick={() => openModal(template, path)}>
                      <img src={path} alt={`Template ${template.template_id} - ${index + 1}`} className={styles.templateImages} />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div onClick={() => openModal(template, template.image_path[0])}>
                  <img src={template.image_path[0]} alt={`Template ${template.template_id} - 1`} className={styles.singleImage} />
                </div>
              )}
              <Button label="Preview" className={`${styles.button} ${styles.outlineButton}`} onClick={() => openModal(template, template.image_path[0])} />
            </div>
          </React.Fragment>
        ))}
      </div>
      {selectedTemplate && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Template Image Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <button className={styles.closeButton} onClick={closeModal}>X</button>
          <div className={`${styles.modalContent} mt-4 text-center`}>
            {selectedTemplate.image_path.length > 1 ? (
              <Slider {...settings} className={styles.sliderImages}>
                {selectedTemplate.image_path.map((path, index) => (
                  <div key={index} className={styles.imageDiv}>
                    <img src={path} alt={`Template ${selectedTemplate.template_id} - ${index + 1}`} className={styles.modalImage} />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className={styles.imageDiv}>
                <img src={selectedImage} alt="Selected Template" className={styles.modalImage} />
              </div>
            )}
            <p className='mt-3'>{selectedTemplate.description}</p>
            <Button label="Choose Template" onClick={navigateToUploads} className={`md:w-4 ${styles.button}`} />
          </div>
        </Modal>

      )}
    </div>

  );
};

export default Templates;
