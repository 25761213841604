// src/components/Welcome.js
import React from 'react';
import './Welcome.css'; // Create this file for Welcome styles

const Welcome = () => {
  return (
    <div className="welcome">
      <h1>Welcome to Hotel Evaluation AI Application.</h1>
      <p>This is the welcome page where you can find an introduction to our application and its features.</p>
    </div>
  );
};

export default Welcome;
