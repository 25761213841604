// src/routes/Routes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Welcome from '../components/Welcome/Welcome';
import ShowListOfTemplates from '../components/ShowListOfTemplates/ShowListOfTemplates';
import UploadFiles from '../components/UploadFiles/UploadFiles';
import TTMtable from '../components/TTMtable/TTMtable';
import OperationalHistory from '../components/OperationalHistory/OperationalHistory';
import CMPreport from '../components/CMPreport/CMPreport';
import IEreports from '../components/IEreports/IEreports';
import ValuationReports from '../components/ValuationReports/ValuationReports';
import FRFreport from '../components/FRFreport/FRFreport';
import KeyStatsNotFound from '../components/KeyStatsNotFound/KeyStatsNotFound';
import MultipleSheets from '../components/MultipleSheets/MultipleSheets';
import DownloadReports from '../components/DownloadReports/DownloadReports';
import Logout from '../components/Logout/Logout';

const AppRoutes = ({ authenticated, setAuthenticated }) => {
  if (!authenticated) {

    return <Navigate to="/" />;

  } else {

    return (
      <Routes>
        <Route path="/welcome" element={<Welcome setAuthenticated={setAuthenticated} />} />
        <Route path="/templates" element={<ShowListOfTemplates />} />
        <Route path="/uploadFiles" element={<UploadFiles />} />
        <Route path="/MultipleSheets" element={<MultipleSheets />} />
        <Route path="/TTMtable" element={<TTMtable />} />
        <Route path="/OperationalHistory" element={<OperationalHistory />} />
        <Route path="/CMPreport" element={<CMPreport />} />
        <Route path="/IEreports" element={<IEreports />} />
        <Route path="/ValuationReports" element={<ValuationReports />} />
        <Route path="/FRFreport" element={<FRFreport />} />
        <Route path="/KeyStatsNotFound" element={<KeyStatsNotFound />} />
        <Route path="/DownloadReports" element={<DownloadReports />} />
        <Route path="/logout" element={<Logout setAuthenticated={setAuthenticated} />} />
        <Route path="*" element={<Navigate to="/welcome" />} />
      </Routes>
    );

  }
  
};

export default AppRoutes;
