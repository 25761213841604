import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './MultipleSheets.css';
import { Button } from 'primereact/button';

const MultipleSheets = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSheetSelection = (event) => {
    setSelectedSheet(event.target.value);
  };

  const handleProceed = async () => {
    if (!selectedSheet) {
      alert('Please select a sheet');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/multiple_sheets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sheet_name: selectedSheet,year: data.year,file_to_use:data.file_to_use }),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.data_missing) {
          navigate('/KeyStatsNotFound', { state: { data: responseData } });
        } else {
          navigate('/TTMtable', { state: { data: responseData } });
        }
        
      } else {
        console.error('Failed to process sheet');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="multiple-sheets-container">
      {isLoading && <div className="loading-message m-auto">Processing... please wait.</div>}
      {!isLoading && (
        <>
          <h1 className='h4 text-600 py-2'>Select a Sheet</h1>
          <div className="sheet-list">
            {data.sheet_names.map((sheet, index) => (
              <div key={index} className="sheet-item">
                <input
                  type="radio"
                  id={`sheet-${index}`}
                  name="sheet"
                  value={sheet}
                  onChange={handleSheetSelection}
                />
                <label htmlFor={`sheet-${index}`}>{sheet}</label>
              </div>
            ))}
          </div>
          <div className="button-container">
            <Button label='Proceed' className='button' onClick={handleProceed} />
          </div>
        </>
      )}
    </div>
  );
};

export default MultipleSheets;
