// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Modal from 'react-modal'; // Import react-modal
import Login from './components/Login';
import Sidebar from './components/Sidebar/Sidebar';
import AppRoutes from './routes/Routes';
import './App.css';

// Set the app element for react-modal for accessibility
Modal.setAppElement('#root');

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        setAuthenticated(response.data.authenticated);
      } catch (error) {
        console.error('Auth check error', error);
      }
    };

    checkAuth();
  }, []);

  return (
    <Router>
      <div className={`App ${authenticated ? 'authenticated' : ''}`}>
        {authenticated ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-lg-2 leftBarDiv">
                <Sidebar />
              </div>
              <div className="col-md-9 col-lg-10 p-0 m-0">
                <main className="ms-sm-auto pt-2 pl-3">
                  <AppRoutes authenticated={authenticated} setAuthenticated={setAuthenticated} />
                </main>
              </div>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login setAuthenticated={setAuthenticated} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
