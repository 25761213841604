import React, { useEffect, useState } from 'react';
import './DownloadReports.css';
import { Button } from 'primereact/button';

const DownloadReports = () => {
  const [files, setFiles] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/api/download_files');
        if (!response.ok) {
          throw new Error('Failed to fetch files');
        }
        const data = await response.json();
        setFiles(data);
      } catch (error) {
        setError('Error fetching files');
      } finally {
        setIsLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const handleDownload = (filePath) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h1 className='h4 text-600 py-2'>Download Reports</h1>
      {isLoading ? (
        <p>Loading files...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <div className='files-container row p-0 m-0'>
          {Object.entries(files).map(([fileName, filePath]) => (
            <div key={fileName} className='file-box col-3'>
              <p>{fileName?.split("_").join(" ")}</p>
              <Button label='Download' className='button outlineButton' icon="pi pi-download" iconPos="right" onClick={() => handleDownload(filePath)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DownloadReports;
