import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FRFreport.css';
import { Button } from 'primereact/button';

const FRFreport = () => {
  const [interestRate, setInterestRate] = useState('');
  const [amortisationPeriod, setAmortisationPeriod] = useState('');
  const [interestOnly, setInterestOnly] = useState('no');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [firstMortgagePercentage, setFirstMortgagePercentage] = useState(65);
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPurchasePrice = async () => {
      try {
        const response = await fetch('/api/input_purchase_price');
        if (response.ok) {
          const data = await response.json();
          setPurchasePrice(data.mean_value);
        } else {
          console.error('Failed to fetch purchase price');
        }
      } catch (error) {
        console.error('Error fetching purchase price:', error);
      }
    };

    fetchPurchasePrice();
  }, []);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (!isNaN(value) || value === '') {
      setter(value);
    }
  };

  const handleProceed = () => {
    // eslint-disable-next-line no-restricted-globals
    let makeSure = confirm('By clicking ‘Finish’, the evaluation will be complete, and reviewing the reports will no longer be possible. Are you sure? Click ‘Proceed’ to continue to downloads!');

    if (makeSure === false) {
      return false;
    } else {
      navigate('/DownloadReports');
    }
  };

  const goToValuationReport = async () => {
    try {
      const response = await fetch('/api/back', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'identifier': 'val_dfs' }),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        const responseData = await response.json();
        navigate('/ValuationReports', { state: { data: responseData } });
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleGenerateReport = async () => {
    if (
      interestRate === '' ||
      amortisationPeriod === '' ||
      purchasePrice === '' ||
      firstMortgagePercentage === '' ||
      isNaN(interestRate) ||
      isNaN(amortisationPeriod) ||
      isNaN(purchasePrice) ||
      isNaN(firstMortgagePercentage)
    ) {
      setError('All fields must be numbers');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = {
      interest_rate: Number(interestRate),
      ammortization: Number(amortisationPeriod),
      interest_only: interestOnly === 'yes',
      purchase_price: Number(purchasePrice),
      first_mortagage_percentage: Number(firstMortgagePercentage),
    };

    try {
      const response = await fetch('/api/get_fixed_rate_financing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to generate report');
      }

      const responseData = await response.json();
      const deserializedData = responseData.map((item) => JSON.parse(item));
      setReportData(deserializedData);
    } catch (error) {
      console.error('Error generating report:', error);
      setError('Error generating report');
    } finally {
      setIsLoading(false);
    }
  };

  const renderTables = () => {
    return reportData.map((table, index) => (
      <div key={index} className='overflowTable'>
        <table className='frfReportTable table table-striped table-hover'>
          <thead>
            <tr>
              <th>Index</th>
              {table.columns.map((header, indexT) => (
                <th key={indexT}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{table.index[rowIndex]}</td>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ));
  };

  return (
    <div>
      <div className='flex justify-content-between align-items-center'>
        <h1 className='h4 text-600 py-2'>Fixed Rate Financing Report</h1>
        <div className="button-container">
          <Button label='Back' className="button outlineButton" outlined onClick={goToValuationReport} />
          <Button label='Finish' className="button" disabled={!reportData} onClick={handleProceed} />
        </div>
      </div>

      <div className='row m-0 p-0 border rounded mt-2 ml-1'>
        <div className="col-2">
          <label className="form-label">Interest Rate (%):</label>
          <input
            className="form-control"
            type="text"
            value={interestRate}
            onChange={handleInputChange(setInterestRate)}
            placeholder="Enter percentage"
          />
        </div>
        <div className="col">
          <label className="form-label">Amortisation Period (years):</label>
          <input
            className="form-control"
            type="text"
            value={amortisationPeriod}
            onChange={handleInputChange(setAmortisationPeriod)}
            placeholder="Enter number of years"
          />
        </div>
        <div className="col-2">
          <label className="form-label">Interest Only:</label>
          <select value={interestOnly}
            className="form-control" onChange={(e) => setInterestOnly(e.target.value)}>
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
        </div>
        <div className="col-2">
          <label className="form-label">Purchase Price:</label>
          <input
            className="form-control"
            type="text"
            value={purchasePrice}
            onChange={handleInputChange(setPurchasePrice)}
            placeholder="Enter purchase price"
          />
        </div>
        <div className="col">
          <label className="form-label">First Mortgage Percentage (%):</label>
          <input
            className="form-control"
            type="text"
            value={firstMortgagePercentage}
            onChange={handleInputChange(setFirstMortgagePercentage)}
            placeholder="Enter a percentage"
          />
        </div>
        <div className="col-12">
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button className='button w-4 ml-3 mt-0' label={isLoading ? 'Generating FRF Report...' : 'Generate FRF Report'} disabled={isLoading} onClick={handleGenerateReport} />
        </div>
      </div>
      {reportData && renderTables()}
    </div>
  );
};

export default FRFreport;
