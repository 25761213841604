// src/components/Logout/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Logout = ({ setAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Call the logout API when the component mounts
    axios.get('/api/logout')
      .then(response => {
        // Handle successful logout
        setAuthenticated(false);
        navigate('/');
      })
      .catch(error => {
        // Handle error
        console.error('There was an error logging out!', error);
      });
  }, [navigate, setAuthenticated]);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
