import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import Slider from 'react-slick';
import { Checkbox } from "primereact/checkbox";
import { Slider } from 'primereact/slider';

const CmpReports = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [growthFactor, setGrowthFactor] = useState(0.35);
  const [pipPerKey, setPipPerKey] = useState('');
  const [outlook, setOutlook] = useState('Optimistic');
  const [bm_occupancy, setBmOccupancy] = useState(0);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let [reportData, setReportData] = useState(null);
  let data = location.state?.data || [];

  useEffect(() => {
    if (data.length > 0) {
      setReportData(data);
      console.log('Initial data set:', data);
    }
  }, [data]);

  const goToOpHistory = async () => {
    try {
      const response = await fetch('/api/back', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'identifier': 'op_hist' }),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        const responseData = await response.json();
        navigate('/OperationalHistory', { state: { data: responseData } });
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProceed = async () => {
    navigate('/IEreports');
  };

  const handleCreateReport = async () => {
    // Validate PIP per Key
    if (pipPerKey === '' || isNaN(pipPerKey)) {
      setError('PIP per Key must be a number');
      return;
    }

    setIsLoading(true);
    setError(null);

    const reportRequestData = {
      beta: growthFactor,
      pip: Number(pipPerKey),
      outlook,
      bm_occupancy
    };

    try {
      const response = await fetch('/api/generate_cmp_report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportRequestData),
      });

      if (!response.ok) {
        throw new Error('Failed to generate report');
      }

      const responseData = await response.json();
      console.log('Report generated successfully:', responseData);
      setReportData(responseData); // Set the response data to state
    } catch (error) {
      console.error('Error generating report:', error);
      setError('Error generating report');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePipPerKeyChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) || value === '') {
      setPipPerKey(value);
    }
  };

  return (
    <div>
      <div className='row px-4'>
        <div className='flex justify-content-between align-items-center'>
          <h1 className='h4 text-600 py-2'>CMP Reports</h1>
          <div className="button-container">
            <Button label='Back' className="button outlineButton" outlined onClick={goToOpHistory} />
            <Button label='Proceed' className='button' disabled={!reportData} onClick={handleProceed} />
          </div>
        </div>
        <div className='py-2 mt-2 border rounded' >
          <div className='flex justify-content-around '>
            <div>
              <label className="form-label" htmlFor="growthFactor">Growth Factor<sup><i className="pi pi-star-fill" style={{ fontSize: '0.5rem', color: 'red' }}></i></sup></label>
              <div className="flex justify-content-start gap-3 pt-2">
                <Slider
                  id="growthFactor"
                  value={growthFactor}
                  onChange={(e) => setGrowthFactor(e.value)}
                  className="w-8rem form-control mt-2"
                  min={0.35}
                  max={0.75}
                  step={0.01}
                  minFractionDigits={2}
                />
                <span className='p-1 border rounded'>{growthFactor}</span>
              </div>
            </div>

            <div>
              <label className='form-label' htmlFor="pipPerKey">PIP <small>(Property Improvement Plan)Per Key</small><sup><i className="pi pi-star-fill" style={{ fontSize: '0.5rem', color: 'red' }}></i></sup></label>
              <InputText
                className='form-control '
                inputId="pipPerKey"
                aria-describedby="username-help"
                value={pipPerKey}
                onChange={handlePipPerKeyChange}
                placeholder="Enter a number"
              />
            </div>
            <div className='text-center'>
              <label className='form-label'>Outlook<sup><i className="pi pi-star-fill" style={{ fontSize: '0.5rem', color: 'red' }}></i></sup></label>
              <div className="flex flex-wrap justify-content-start gap-2 form-control border-0">
                <div className="flex align-items-center">
                  <Checkbox inputId="Optimistic" name="outlook" value="Optimistic" onChange={(e) => setOutlook(e.target.value)} checked={outlook === 'Optimistic'} />
                  <label htmlFor="Optimistic" className="ml-2">Optimistic</label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox inputId="Conservative" name="outlook" value="Conservative" onChange={(e) => setOutlook(e.target.value)} checked={outlook === 'Conservative'} />
                  <label htmlFor="Conservative" className="ml-2">Conservative</label>
                </div>
              </div>
            </div>
            <div>
              <label className='form-label' htmlFor="bm_occupancy">Benchmark Occupancy</label>
              <InputText
                className='form-control '
                inputId="bm_occupancy"
                aria-describedby="username-help"
                value={bm_occupancy}
                onChange={(e) => setBmOccupancy(e.target.value)}
                placeholder="Enter a number"
              />
            </div>
            <div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <Button className='button' label={isLoading ? 'Forecasting...' : 'Forecast'} tooltip="Forecast CMP Report" tooltipOptions={{ position: 'top' }} disabled={isLoading} onClick={handleCreateReport} />
            </div>
          </div>
        </div>
      </div>

      {
        reportData && (
          <div className='pl-3'>
            <h4>Report Data</h4>
            <div className='overflowTable'>
              <table border="1" className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Years</th>
                    <th>Occupancy(%)</th>
                    <th>Occupancy % Change</th>
                    <th>Occupancy Market(%)</th>
                    <th>Occupancy Market % Change</th>
                    <th>Occupancy Penetration(%)</th>
                    <th>ADR($)</th>
                    <th>ADR % Change</th>
                    <th>ADR Market($)</th>
                    <th>ADR Market % Change</th>
                    <th>ADR Penetration(%)</th>
                    <th>RevPAR($)</th>
                    <th>RevPAR % Change</th>
                    <th>RevPAR Market($)</th>
                    <th>RevPAR Market % Change</th>
                    <th>RevPAR Penetration(%)</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Years}</td>
                      <td>{item.Occupancy}</td>
                      <td>{item.Occupancy_perc_change}</td>
                      <td>{item.Occupancy_Market}</td>
                      <td>{item.Occupancy_Market_perc_change}</td>
                      <td>{item.Occupancy_Penetration}</td>
                      <td>{item.ADR}</td>
                      <td>{item.ADR_perc_change}</td>
                      <td>{item.ADR_Market}</td>
                      <td>{item.ADR_Market_perc_change}</td>
                      <td>{item.ADR_Penetration}</td>
                      <td>{item.RevPAR}</td>
                      <td>{item.RevPAR_perc_change}</td>
                      <td>{item.RevPAR_Market}</td>
                      <td>{item.RevPAR_Market_perc_change}</td>
                      <td>{item.RevPAR_Penetration}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div >
  );
};

export default CmpReports;
