import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UploadFiles.css';
import { Button } from 'primereact/button';
import loader from "../../assets/Hourglass.gif";
import loadingSound from "../../assets/mixkit-tick-tock-clock-timer-1045.wav";

const UploadFiles = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState({ ttm: null, str: null, benchmark: null });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const audioElement = document.getElementById('loading-audio');
    audioElement.volume = 0.1; // Set the volume to 10%

    if (isLoading) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0;
    }

    return () => {
      audioElement.pause();
      audioElement.currentTime = 0;
    };
  }, [isLoading]);

  const handleUpload = (event, type) => {
    const file = event.target.files[0];
    setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
  };

  const goToListTemplates = () => {
    navigate('/templates');
  };

  const handleProceed = async () => {
    if (!files.ttm) {
      alert('TTM file is mandatory');
      return;
    }

    let formData = new FormData();
    formData.append('ttm_file', files.ttm, files.ttm.name);
    if (files.str) {
      formData.append('str_file', files.str, files.str.name);
    }
    if (files.benchmark) {
      formData.append('bm_str_file', files.benchmark, files.benchmark.name);
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/upload_files', {
        method: 'POST',
        body: formData,
      });

      if (response.status === 200) {
        console.log('Files uploaded successfully');
        const responseData = await response.json();
        setIsLoading(false);
        if (responseData.sheet_names) {
          navigate('/MultipleSheets', { state: { data: responseData } });
        } else if (responseData.data_missing) {
          navigate('/KeyStatsNotFound', { state: { data: responseData } });
        } else {
          navigate('/TTMtable', { state: { data: responseData } });
        }
      } else {
        console.error(response)
        console.error('Failed to upload files');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <audio id="loading-audio" src={loadingSound} loop />
      {isLoading && (
        <div className="loading-message h-screen justify-content-center align-content-center m-auto">
          <div className='h-20rem text-center flex justify-content-center align-content-center '>
            <div className='text-center h5 text-muted'>
              <img className='mb-3' src={loader} alt="loader" height={70} width={70} />
              <br />
              <p> Please wait, uploaded file(s) are being processed...</p>
              <p>this can take 1-2 minutes.</p>
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div className='flex justify-content-between align-items-center'>
            <h1 className='h4 text-600 py-2'>Upload Files</h1>
            <div className="button-container">
              <Button label='Back' className="button outlineButton" outlined onClick={goToListTemplates} />
              <Button label='Proceed' className='button' onClick={handleProceed} />
            </div>
          </div>

          <div className="col-md-10 col-lg-8 col-xl-6 card py-3">
            <div className="upload-box">
              <h2 className='h5 text-900'>*TTM</h2>
              <p className='text-400'>Upload latest Hotel Financials</p>
              <div className="flex justify-content-start align-items-center">
                <div className="fileInputWrap">
                  <label className="uploadButton" htmlFor="upload">Upload File <i className="pi pi-paperclip px-2" ></i></label>
                  <input id="upload" type="file" onChange={(event) => handleUpload(event, 'ttm')} />
                </div>
                {files?.ttm && <div className='fileNameWarper text-truncate'>
                  {files?.ttm?.name}
                </div>}
              </div>
            </div>
            <div className="upload-box">
              <h2 className='h5 text-900'>*STR</h2>
              <p className='text-400'>Upload Latest STR report</p>
              <div className="flex justify-content-start align-items-center">
                <div className="fileInputWrap">
                  <label className="uploadButton" htmlFor="strUpload">Upload File <i className="pi pi-paperclip px-2" ></i></label>
                  <input id="strUpload" type="file" onChange={(event) => handleUpload(event, 'str')} />
                </div>
                {files?.str && <div className='fileNameWarper text-truncate'>
                  {files?.str?.name}
                </div>}
              </div>
            </div>
            <div className="upload-box">
              <h2 className='h5 text-900'>Benchmark STR</h2>
              <p className='text-400'>Upload Hotel's benchmark performance STR</p>
              <div className="flex justify-content-start align-items-center">
                <div className="fileInputWrap">
                  <label className="uploadButton" htmlFor="benchmarkUpload">Upload File <i className="pi pi-paperclip px-2" ></i></label>
                  <input id="benchmarkUpload" type="file" onChange={(event) => handleUpload(event, 'benchmark')} />
                </div>
                {files?.benchmark && <div className='fileNameWarper text-truncate'>
                  {files?.benchmark?.name}
                </div>}
              </div>
            </div>
            <p className='h6 text-600 p-3'>* Mandatory Fields</p>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadFiles;
